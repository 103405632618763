var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs }
      }),
      _c(
        "v-card",
        { attrs: { outlined: "", flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "mt-5 mb-3 px-12" },
            [
              _c("app-form", {
                attrs: {
                  fields: _vm.fields,
                  "submit-button": _vm.submitButton,
                  footerClass: "col-12 text-center mt-5"
                },
                on: {
                  canceled: _vm.resetIntegration,
                  submitted: _vm.handleSubmit
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }